input:-internal-autofill-selected:focus {
  background: white !important;
}
.App {
  background-color: #1a1c1f;
  background: #1a1c1f;
}
@font-face {
  font-family: "Light"; /*Light*/
  src: local("HelveticaNeue Thin"),
    url("./fonts/HelveticaNeue Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Regular"; /*Regular*/
  src: local("HelveticaNeue Light"),
    url("./fonts/HelveticaNeue Light.ttf") format("truetype");
}
@font-face {
  font-family: "Medium"; /*Medium*/
  src: local("HelveticaNeue Medium"),
    url("./fonts/HelveticaNeue Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Bold"; /*Medium*/
  src: local("Helvetica Neu Bold"), url("./fonts/Helvetica Neu Bold.ttf") format("truetype");
}
