.DatePicker {
  display: flex;
  column-gap: 5%;
  .selector {
    align-items: center;
    height: 3.438rem;
    width: 30%;
    border-radius: 0.313rem;
    border: 1px solid #dadada;
    margin-top: 1rem;
    -webkit-appearance: none;
    text-align: center;
    outline: none;
    padding: 1rem;
    color: #000;
    font-family: "Medium";
    font-size: 0.9rem;
    background-image: url("../../../../Assets/Icons/arrow-down.png");
    background-position: calc(100% - 20px) calc(1em + 8px),
      calc(100% - 15px) calc(1em + 8px), 100% 0;
    background-size: 0.75rem;
    background-repeat: no-repeat;
  }

  .selector-month {
    height: 3.438rem;
    width: 40%;
    border-radius: 0.313rem;
    border: 1px solid #dadada;
    margin-top: 1rem;
    -webkit-appearance: none;
    text-align: center;
    outline: none;
    padding: 1rem;
    color: #000;
    font-family: "Medium";
    font-size: 0.9rem;
    background-image: url("../../../../Assets/Icons/arrow-down.png");
    background-position: calc(100% - 20px) calc(1em + 8px),
      calc(100% - 15px) calc(1em + 8px), 100% 0;
    background-size: 0.75rem;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 600px) {
  .DatePicker {
    .selector {
      height: 2.563rem;
      padding: 0;
      background-position: calc(100% - 20px) calc(1em),
        calc(100% - 15px) calc(1em + 8px), 100% 0;
      text-align: left;
      padding-left: 0.688rem;
    }
    .selector-month{
      height: 2.563rem;
      padding: 0;
      background-position: calc(100% - 20px) calc(1em),
        calc(100% - 15px) calc(1em + 8px), 100% 0;
      text-align: left;
      padding-left: 0.688rem;
    }
  }
}
