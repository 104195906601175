.SignupWrapper {
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  .SignupWrapper-container {
    width: 38%;
    border: 1px solid #dadada;
    min-height: 36.313rem;
    border-radius: 0.625rem;
    padding-top: 2.938rem;
    text-align: center;
    max-height: max-content;
    .header-container {
      width: 68%;
      margin: auto;
      .logo-container {
        width: 100%;
        display: flex;
        align-items: center;
        .back-icon {
          height: 1.813rem;
          width: 1.813rem;
          margin-left: -1.813rem;
          cursor: pointer;
        }
        .logo {
          width: 9.563rem;
          height: 2.125rem;
          margin: auto;
        }
      }
      .signup-header {
        font-family: "Medium";
        font-size: 1.25rem;
        margin-top: 2.063rem;
      }
      .error-text {
        color: #d85757;
        font-size: 0.8rem;
        font-family: "Regular";
        margin-top: 0.5rem;
        text-align: left;
        margin-left: 0.2rem;
      }
      .success-text {
        color: #51b62d;
        font-size: 0.8rem;
        font-family: "Regular";
        margin-top: 0.5rem;
        text-align: left;
        margin-left: 0.2rem;
      }
      .signup-subheader {
        width: 80%;
        margin: auto;
        font-family: "Regular";
        font-size: 1rem;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        margin-bottom: 2.625rem;
      }
      .username-wrapper {
        display: flex;
        width: 100%;
        .input-field {
          width: 70%;
        }
        .domain-name {
          margin-top: 1rem;
          width: 30%;
          color: #000;
          font-family: "Medium";
          font-size: 0.9rem;
          align-items: center;
          display: flex;
          height: 3.438rem;
          justify-content: flex-end;
        }
      }
      .requirements {
        font-size: 0.8rem;
        color: #787878;
        text-align: justify;
        margin-top: 0.5rem;
      }
      .check-container {
        color: #b5b5b5;
        text-align: left;
        font-size: 0.875rem;
        margin-top: 1.938rem;
        display: flex;
        cursor: pointer;
        span {
          a {
            color: #b5b5b5;
            text-decoration: underline;
          }
          .check-error {
            color: #d85757;
          }
        }
        .check-box {
          height: 1.375rem;
          width: 1.375rem;
          min-height: 1.375rem;
          min-width: 1.375rem;
          border: 1.5px solid #d9d9d9;
          border-radius: 0.313rem;
          margin-right: 0.75rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .check {
            height: 0.875rem;
            width: 0.875rem;
          }
        }
        .checked {
          border-color: #147cff;
        }
        .checked-error {
          border-color: #d85757;
          border-width: 1px;
        }
      }
      .check-error {
        color: #d85757;
      }
      .btn-container-right {
        display: flex;
        justify-content: flex-end;
        margin-top: 2.375rem;
        margin-bottom: 2rem;
      }

      .required-text {
        text-align: left;
        font-size: 0.8rem;
        color: #d85757;
        margin-top: 0.3rem;
      }
      .sub-text {
        text-align: left;
        font-size: 0.7rem;
        color: #b6b6b6;
        margin-top: 0.1rem;
      }
    }
    .success-container {
      width: 68%;
      margin: auto;
      display: flex;
      place-items: center;
      justify-content: center;
      min-height: inherit;

      .success-wrapper {
        width: 100%;
        .btn-container-center {
          display: flex;
          justify-content: center;
          margin-top: 3.063rem;
          margin-bottom: 2rem;
        }
        .success-icon-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .success-icon {
            height: 3.063rem;
            width: 3.063rem;
          }
        }
        .success-header {
          font-size: 1.5rem;
          color: #000;
          margin-top: 1.875rem;
          font-weight: 500;
        }

        .success-subheader {
          font-size: 1rem;
          color: #000;
          margin-top: 1.875rem;
          span {
            color: #939191;
            cursor: pointer;
            position: relative;
            .copy {
              color: white;
              position: absolute;
              top: -1.5rem;
              right: 0rem;
              font-size: 0.625rem;
              padding: 0.3rem 0.6rem;
              background-color: #acacac;
              border-radius: 0.2rem;

            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .SignupWrapper {
    .SignupWrapper-container {
      width: 90%;
      .header-container {
        width: 90%;
        .logo-container {
          margin-left: -1.813rem;
          .back-icon {
            margin-left: 1.813rem;
          }
        }
      }
      .success-container {
        width: 90%;
      }
    }
  }
}
