.SignupWrapper {
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  .SignupWrapper-container {
    width: 38%;
    border: 1px solid #dadada;
    min-height: 36.313rem;
    border-radius: 0.625rem;
    padding-top: 2.938rem;
    text-align: center;
    max-height: max-content;
    .header-container {
      width: 68%;
      margin: auto;
      .logo-container {
        width: 100%;
        display: flex;
        align-items: center;
        .back-icon {
          height: 1.813rem;
          width: 1.813rem;
          margin-left: -1.813rem;
          cursor: pointer;
        }
        .logo {
          width: 9.563rem;
          height: 2.125rem;
          margin: auto;
        }
      }
      .signup-header {
        font-family: "Medium";
        font-size: 1.25rem;
        margin-top: 2.063rem;
      }
      .error-text {
        color: #d85757;
        font-size: 0.8rem;
        font-family: "Regular";
        margin-top: 0.5rem;
        text-align: left;
        margin-left: 0.2rem;
      }
      .success-text {
        color: #51b62d;
        font-size: 0.8rem;
        font-family: "Regular";
        margin-top: 0.5rem;
        text-align: left;
        margin-left: 0.2rem;
      }
      .signup-subheader {
        width: 80%;
        margin: auto;
        font-family: "Regular";
        font-size: 1rem;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        margin-bottom: 2.625rem;
      }
      .username-wrapper {
        display: flex;
        width: 100%;
        .input-field {
          width: 70%;
        }
        .domain-name {
          margin-top: 1rem;
          width: 30%;
          color: #000;
          font-family: "Medium";
          font-size: 0.9rem;
          align-items: center;
          display: flex;
          height: 3.438rem;
          justify-content: flex-end;
        }
      }
      .requirements {
        font-size: 0.8rem;
        color: #787878;
        text-align: justify;
        margin-top: 0.5rem;
      }

      .btn-container-right {
        display: flex;
        justify-content: flex-end;
        margin-top: 2.375rem;
        margin-bottom: 2rem;
      }
      .required-text {
        text-align: left;
        font-size: 0.8rem;
        color: #d85757;
        margin-top: 0.3rem;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .SignupWrapper {
    .SignupWrapper-container {
      width: 90%;
      .header-container {
        width: 90%;
        .logo-container {
          margin-left: -1.813rem;
          .back-icon {
            margin-left: 1.813rem;
          }
        }
      }
    }
  }
}
