.SignupSuccess-Wrapper {
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  .SignupSuccess-Container {
    width: 32%;
    box-shadow: 0 0 8px #43434320;
    min-height: max-content;
    border-radius: 0.625rem;
    padding-top: 2.938rem;
    text-align: center;
    max-height: max-content;
    padding-bottom: 2.125rem;
    .logo-container {
      width: 91%;
      display: flex;
      margin: auto;
      .back-icon {
        height: 2.313rem;
        width: 2.313rem;
        cursor: pointer;
      }
      .logo-wrapper {
        width: 100%;
        .logo {
          height: 2.813rem;
          width: 2.813rem;
          border-radius: 0.313rem;
        }
      }
    }
    .header-container-success {
      width: 82%;
      margin: auto;

      .signup-header {
        font-family: "Medium";
        font-size: 1.875rem;
        margin-top: 2.063rem;
        text-align: left;
        color: black;
      }
      .signup-subheader {
        font-size: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 1.063rem;
        text-align: left;
        color: black;
      }
      .social {
        display: flex;
        column-gap: 0.938rem;
        justify-content: center;
        .social-container {
          border: 1px solid #eaeaea;
          border-radius: 0.313rem;
          width: 3.063rem;
          height: 2.75rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            width: 1.313rem;
            height: 1.313rem;
          }
        }
      }
      .copy-link {
        text-align: left;
        font-size: 1rem;
        color: #000;
        margin-bottom: 0.938rem;
        margin-top: 1.125rem;
      }
      .link-container {
        width: 100%;
        border: 1px solid #eaeaea;
        height: 2.75rem;
        border-radius: 0.313rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .link {
          width: 80%;
          text-align: left;
          padding-left: 0.625rem;
          overflow: hidden !important;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .copy-btn {
          width: 4.688rem;
          height: 2.75rem;
          border-top-right-radius: 0.313rem;
          border-bottom-right-radius: 0.313rem;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #147cff;
          cursor: pointer;
        }
      }
      .product-hunt {
        width: 14.125rem;
        height: 2.75rem;
        margin-top: 1.375rem;
      }
      .go-btn {
        width: 100%;
        background-color: #396af4;
        height: 2.75rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.438rem;
        border-radius: 0.313rem;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .SignupSuccess-Wrapper {
    .SignupSuccess-Container {
      width: 90%;
      .logo-container {
        .back-icon {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
      .header-container-success {
        .signup-header {
          font-size: 1.438rem;
        }
        .signup-subheader {
          margin-top: 0.938rem;
          margin-bottom: 1.313rem;
        }
        .social {
          column-gap: 0.563rem;
        }
        .link-container {
          height: 2.063rem;
          .copy-btn {
            height: 2.063rem;
          }
        }
      }
    }
  }
}
