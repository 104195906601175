@import "../../../colors.scss";

//////////////////////////////////  Variables //////////////////////////////////////

.login-lightmode {
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ._loginContainer {
    font-family: "Poppins";
    width: 31%;
    margin: auto;
    .form-wrapper {
      .header-container {
        display: flex;
        justify-content: center;
        margin-bottom: 3.5rem;
        .image {
          height: 2.125rem;
          width: 9.563rem;
          filter: brightness(0) saturate(100%) invert(30%) sepia(100%) saturate(3757%) hue-rotate(205deg) brightness(98%) contrast(87%);
        }
      }
      .loginwrapper {
        .personal{
          text-align: center;
          color: #343434;
          font-size: 1rem;
          margin-top: 1.813rem;
          cursor: pointer;
          a{
            color: #343434;
          }
        }
        .learn-more-link{
          color: #343434;
          font-size: 1rem;
          cursor: pointer;
          position: fixed;
          left: 50%;
          bottom: 2rem;
          transform: translate(-50%, -50%);
          margin: 0 auto;
          display: block;
        }
        .header {
          color:  #343434;
          font-size: 1.25rem;
          margin-left: 1.2rem;
        }
        .form-fail {
          font-size: 0.813rem;
          color: red;
          margin-left: 1.2rem;
          margin-top: 0.3rem;
          display: flex;
          align-items: center;
          .danger-login {
            height: 0.8rem;
            width: 0.8rem;
            margin-right: 0.5rem;
          }
        }
        .forgetText {
          color: #343434;
          text-align: right;
          font-weight: 200;
          text-decoration: underline;
          margin-top: 1.5rem;
          margin-bottom: 1.4rem;
          cursor: pointer;
        }
      }
      .forgot-password {
        color: #343434;
        .forgot-password-header {
          font-size: 1.25rem;
          font-weight: 400;
          margin-bottom: 2.5rem;
        }
        .forgot-password-text {
          font-weight: 500;
          font-size: 1rem;
          span {
            text-decoration: underline;
          }
        }
        .btn-back {
          background-color: #147CFF;
          height: 2.688rem;
          border-radius: 0.625rem;
          color: #fff;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7.188rem;
          margin: auto;
          margin-top: 4.5rem;
          cursor: pointer;
        }
      }
    }
  }
}
.login-darkmode {
  background-color: #1a1c1f;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ._loginContainer {
    font-family: "Poppins";
    width: 31%;
    margin: auto;
    .form-wrapper {
      .header-container {
        display: flex;
        justify-content: center;
        margin-bottom: 3.5rem;
        .image {
          height: 2.125rem;
          width: 9.563rem;
        }
      }
      .loginwrapper {
        .personal{
          text-align: center;
          color: white;
          font-size: 1rem;
          margin-top: 1.813rem;
          cursor: pointer;
          a{
            color: white;
          }
        }
        .learn-more-link{
          color: #fff;
          font-size: 1rem;
          cursor: pointer;
          position: fixed;
          left: 50%;
          bottom: 2rem;
          transform: translate(-50%, -50%);
          margin: 0 auto;
          display: block;
        }
        .learn-more-link{
          color: white;
          font-size: 1rem;
          cursor: pointer;
          position: fixed;
          left: 50%;
          bottom: 2rem;
          transform: translate(-50%, -50%);
          margin: 0 auto;
          display: block;
        }
        .header {
          color: $white;
          font-size: 1.25rem;
          margin-left: 1.2rem;
        }
        .form-fail {
          font-size: 0.813rem;
          color: red;
          margin-left: 1.2rem;
          margin-top: 0.3rem;
          display: flex;
          align-items: center;
          .danger-login {
            height: 0.8rem;
            width: 0.8rem;
            margin-right: 0.5rem;
          }
        }
        .forgetText {
          color: #fff;
          text-align: right;
          font-weight: 200;
          text-decoration: underline;
          margin-top: 1.5rem;
          margin-bottom: 1.4rem;
          cursor: pointer;
        }
      }
      .forgot-password {
        color: $white;
        .forgot-password-header {
          font-size: 1.25rem;
          font-weight: 400;
          margin-bottom: 2.5rem;
        }
        .forgot-password-text {
          font-weight: 500;
          font-size: 1rem;
          span {
            text-decoration: underline;
          }
        }
        .btn-back {
          background-color: $white;
          height: 2.688rem;
          border-radius: 0.625rem;
          color: $black;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7.188rem;
          margin: auto;
          margin-top: 4.5rem;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .login-lightmode {
    ._loginContainer {
      overflow-y: auto;
      width: 90%;
      .form-wrapper {
        .forgetText {
          margin-top: 0.5rem;
          margin-bottom: 3rem;
        }
      }
    }
  }
  .login-darkmode {
    ._loginContainer {
      overflow-y: auto;
      width: 90%;
      .form-wrapper {
        .forgetText {
          margin-top: 0.5rem;
          margin-bottom: 3rem;
        }
        .loginwrapper{
          .learn-more-link{
            display: none;
          }
        }
      }
    }
  }
}
