.main-body {
  background-color: white;
  display: flex;
  justify-content: center;
}
.floating-button{
  margin-top: 1rem;
  position: fixed;
  width: 320px;
  background-color: #0168E1;
  color: #FFFFFF;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;

  @media only screen and (max-width: 425px) {
    width: 95%;
  }
}
.fb-selected{
  margin-top: 90dvh;
}
.body-content{
  margin-top: 5rem;
  max-width: 320px;
  padding: 10px;

  @media only screen and (max-width: 425px) {
    min-width: 85%;
  }
}
.bc-selected{
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.floating-title{
  font-size: 1.25rem;
  font-weight: 600;
}
.floating-footer{
  font-size: 0.75rem;
}
.title{
  font-size: 1.5rem !important;
  margin-bottom: 0.5rem !important;
}
.top-text{
  font-size: 0.75rem;
}
.content-card{
  margin-top: 2rem;
  border: 1px solid #CAE2FF;
  border-radius: 15px;
  padding: 20px;
  background-color: #F1F7FF;
  cursor: pointer;
}
.card-top{
  display: flex;
  justify-content: space-between;
}
.card-title{
  font-size: 1.25rem;
  font-weight: 600;
}
.card-price{
  display: flex;
  align-items: center;
}
.price-normal{
  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: line-through;
}
.price-discount{
  margin-left: 10px;
  font-size: 1.5rem;
}
.select-box{
  background-color: #D9D9D9;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.selected{
  background-color: #51B62D !important;
  border-color: #51B62D !important;
  color: #FFFFFF !important;
}
.img-check{
  width: 60%;
}
.card-content{
  margin-top: 20px;
  padding: 0px;
}
.select-button{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 2px solid #0168E1;
}
.select-text{
  color: #0168E1;
  font-size: 1rem;
  font-weight: 600;
}
.bottom-text{
  font-size: 0.75rem;
  text-align: center;
}
  @media only screen and (max-width: 600px) {
    .SignupWrapper {
      .SignupWrapper-container {
        width: 90%;
        .header-container {
          width: 90%;
          .logo-container {
            margin-left: -1.813rem;
            .back-icon {
              margin-left: 1.813rem;
            }
          }
        }
        .success-container {
          width: 90%;
        }
      }
    }
  }