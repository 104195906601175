.DomainWrapper {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  place-items: center;
  justify-content: center;
  .DomainWrapper-container {
    width: 50%;
    .header-conatiner {
      .logo-container {
        width: 100%;
        display: flex;
        align-items: center;
        .back-icon {
          height: 1.813rem;
          width: 1.813rem;
          margin-left: -1.813rem;
          cursor: pointer;
        }
        .logo {
          width: 9.563rem;
          height: 2.125rem;
          margin: auto;
        }
      }
      .header-domain {
        text-align: center;
        font-family: "Regular";
        font-size: 1.875rem;
        color: #000;
        margin-top: 1.938rem;
        .bold {
          font-family: "Medium";
        }
      }
    }
    .domain-loader {
      border: 2px solid #dadada;
      border-radius: 50%;
      border-top: 2px solid #116fe5;
      width: 35px;
      height: 35px;
      -webkit-animation: spin 0.6s linear infinite; /* Safari */
      animation: spin 0.6s linear infinite;
      margin: auto;
      margin-top: 3.25rem;
    }
    .Domain-list-container {
      width: 80%;
      margin: auto;
      margin-top: 3.25rem;
      overflow-y: auto;
      border: 1px solid #dadada;
      border-radius: 0.625rem;
      height: max-content;
      .domain-details {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 5.625rem;
        border-bottom: 1px solid #dadada;
        .detail-container {
          display: flex;
          .domain-dp {
            height: 3.438rem;
            width: 3.438rem;
            border-radius: 0.313rem;
            border: 1px solid #000;
            display: flex;
            font-size: 0.625rem;
            font-family: "Medium";
            color: #000;
            justify-content: center;
            align-items: center;
            .fire-conatiner{
              position: relative;
              .fire-icon{
                height: 3.438rem;
                width: 3.438rem;
              }
              .first-content {
                position: absolute;
                height: 3.438rem;
                width: 3.438rem;
                top: 25%;
                right: 35%;
                left: 35%;
                transform: translateX(-50%, -50%);
                color: white;

                .arrow {
                  height: 1rem;
                  width: 1rem;
                }
              }
            }
            .content {
              .arrow {
                height: 1rem;
                width: 1rem;
              }
            }
          }
          .domain-name {
            margin-left: 1.938rem;
            .name {
              font-family: "Medium";
              font-size: 1.25rem;
              color: #000;
            }
            .powered {
              font-family: "Regular";
              font-size: 0.9rem;
              color: #858585;
              .span-style {
                color: #000;
                margin-left: 0.3rem;
                text-decoration: underline;
              }
            }
          }
        }
      }
      .domain-details:last-child {
        border-bottom: none;
      }
    }
    .button-container {
      display: flex;
      column-gap: 0.75rem;
      margin-top: 3.563rem;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 600px) {
  .DomainWrapper {
    .DomainWrapper-container {
      width: 100%;
      .header-conatiner {
        width: 90%;
        margin: auto;
        .logo-container {
          margin-left: -1.813rem;
          .back-icon {
            margin-left: 1.813rem;
          }
        }
        .header-domain {
          font-size: 1.125rem;
          margin-top: 1.563rem;
        }
      }
      .Domain-list-container {
        width: 100%;
        border: 0;
        .domain-details {
          .detail-container {
            .domain-dp {
              height: 2.875rem;
              width: 2.875rem;
            }
            .domain-name {
              margin-left: 1.5rem;
            }
          }
        }
      }
      .button-container {
        flex-direction: column;
        width: max-content;
        margin: auto;
        align-items: center;
        margin-top: 3.563rem;
        row-gap: 0.625rem;
      }
    }
  }
}
