.LoadingPage-darkmode {
  background-color: #1a1c1f;
  height: 100vh;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: -20px;
  .loading-anim{
    display: flex;
    width: 120px;
    img {
      height: 20px;
      width: 20px;
      margin-right: 3px;
    }
    .image-1 {
      animation: slide1 0.3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-2 {
      animation: slide2 0.3s;
      animation-delay: 0.3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-3 {
      animation: slide1 0.3s;
      animation-delay: 0.6s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-4 {
      animation: slide1 0.3s;
      animation-delay: 0.9s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-5 {
      animation: slide2 0.3s;
      animation-delay: 1.2s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-6 {
      animation: slide1 0.3s;
      animation-delay: 1.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-7 {
      animation: slide1 0.3s;
      animation-delay: 1.8s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    @keyframes slide1 {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes slide2 {
      0% {
        margin-top: -20px;
        opacity: 0;
      }
      100% {
        margin-top: 0px;
        opacity: 1;
      }
    }
  }
}
.LoadingPage-lightmode {
  background-color: #fff;
  height: 100vh;
  width: 100%;
  color: #3e3e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: -20px;

  .loading-anim{
    display: flex;
    width: 120px;
    img {
      height: 20px;
      width: 20px;
      margin-right: 3px;
      filter: brightness(0) saturate(100%) invert(27%) sepia(86%) saturate(1771%)
      hue-rotate(207deg) brightness(103%) contrast(105%);
    }
    .image-1 {
      animation: slide1 0.3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-2 {
      animation: slide2 0.3s;
      animation-delay: 0.3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-3 {
      animation: slide1 0.3s;
      animation-delay: 0.6s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-4 {
      animation: slide1 0.3s;
      animation-delay: 0.9s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-5 {
      animation: slide2 0.3s;
      animation-delay: 1.2s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-6 {
      animation: slide1 0.3s;
      animation-delay: 1.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .image-7 {
      animation: slide1 0.3s;
      animation-delay: 1.8s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    @keyframes slide1 {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes slide2 {
      0% {
        margin-top: -20px;
        opacity: 0;
      }
      100% {
        margin-top: 0px;
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .LoadingPage-darkmode{
    padding-top: 0;
    min-height: 100vh;
    .loading-anim{ 
      .image-2 {
        display: none;
      }
      .image-7 {
        display: none;
      }

    
     
      
    }
  }
  .LoadingPage-lightmode {
    padding-top: 0;
    min-height: 100vh;
    .loading-anim{ 
      .image-2 {
        display: none;
      }
      .image-7 {
        display: none;
      }

    
     
      
    }
  }
}
