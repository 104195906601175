.InputContainer {
  height: 3.438rem;
  width: 100%;
  border-radius: 0.313rem;
  border: 1px solid #dadada;
  margin-top: 1rem;
  .zp-input-container {
    height: 100%;
    width: 100%;
    border-radius: 0.313rem;
    background-color: transparent;
    outline: none;
    border: none;
    color: #000;
    font-family: "Medium";
    font-size: 0.9rem;
    padding-right: 1.625rem;
    padding-left: 1.625rem;
  }
  .zp-input-container::placeholder {
    color: #a6a6a6;
    font-family: "Medium";
    font-size: 0.9rem;
  }
 
  .icon-container {
    width: 10%;
    height: 2.688rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0.9rem;
    .icon {
      height: 1.063rem;
      width: 1.063rem;
      cursor: pointer;
    }
  }
}
.InputUsername {
  display: flex;
  align-items: center;
}
.required-text {
  text-align: left;
  font-size: 0.8rem;
  color: #d85757;
  margin-top: 0.3rem;
}
.success-text {
  text-align: left;
  font-size: 0.8rem;
  color: #51b62d;
  margin-top: 0.3rem;
}
@media only screen and (max-width: 600px) {
  .InputContainer {
    height: 2.563rem;
    .zp-input-container {
      padding-right: 0.688rem;
      padding-left: 0.688rem;
    }
    .domain-name {
      width: 100%;
      height: 2.563rem;
    }
  }
}
