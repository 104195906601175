.main-body-pay-darkmode {
  background-color: #242629;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .inputs-wrapper{
    .check-container {
      color: #b5b5b5;
      text-align: left;
      font-size: 0.875rem;
      margin-top: 1.938rem;
      display: flex;
      cursor: pointer;
      span {
        a {
          color: #b5b5b5;
          text-decoration: underline;
        }
        .check-error {
          color: #d85757;
        }
      }
      .check-box {
        height: 1.375rem;
        width: 1.375rem;
        min-height: 1.375rem;
        min-width: 1.375rem;
        border: 1.5px solid #d9d9d9;
        border-radius: 0.313rem;
        margin-right: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .check {
          height: 0.875rem;
          width: 0.875rem;
        }
      }
      .checked {
        border-color: #147cff;
      }
      .checked-error {
        border-color: #d85757;
        border-width: 1px;
      }
    }
    .check-error {
      color: #d85757;
    }
    .back-link{
      color: white;
      margin-top: 20px;
    }
    .back-link:hover{
      text-decoration-line: underline;
    }
  }
}
.main-body-pay-lightmode {
  background-color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .inputs-wrapper{
    .check-container {
      color: #b5b5b5;
      text-align: left;
      font-size: 0.875rem;
      margin-top: 1.938rem;
      display: flex;
      cursor: pointer;
      span {
        a {
          color: #b5b5b5;
          text-decoration: underline;
        }
        .check-error {
          color: #d85757;
        }
      }
      .check-box {
        height: 1.375rem;
        width: 1.375rem;
        min-height: 1.375rem;
        min-width: 1.375rem;
        border: 1.5px solid #d9d9d9;
        border-radius: 0.313rem;
        margin-right: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .check {
          height: 0.875rem;
          width: 0.875rem;
        }
      }
      .checked {
        border-color: #147cff;
      }
      .checked-error {
        border-color: #d85757;
        border-width: 1px;
      }
    }
    .check-error {
      color: #d85757;
    }
    .back-link{
      color: white;
      margin-top: 20px;
    }
    .back-link:hover{
      text-decoration-line: underline;
    }
  }
}
.input-container{
  max-width: 300px;
}

.inputs-wrapper{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: 1rem 2rem;
  .input-container{
    margin-bottom: 1rem;
  }
}
  @media only screen and (max-width: 600px) {

    .inputs-wrapper{
      width: 90% !important;
    }
    .SignupWrapper {
      .SignupWrapper-container {
        width: 90%;
        .header-container {
          width: 90%;
          .logo-container {
            margin-left: -1.813rem;
            .back-icon {
              margin-left: 1.813rem;
            }
          }
        }
        .success-container {
          width: 90%;
        }
      }
    }
  }