@import "../../colors.scss";

//////////////////////////////////  Variables //////////////////////////////////////

$defultTextFont: "SF Pro Display", sans-serif;
$userNamePlaceHolder: "../../../Assets/Icons/vuesax-linear-profile.svg";

.mySlide {
  display: none;
}
img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
  
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: white;
  font-size: 34px;
  width: 100%;
  font-weight: bold;
  font-family: $defultTextFont;
}

/* Number text (1/3 etc) */
.numbertext {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-weight: bold;
  font-family: $defultTextFont;
  margin-top: 30px;
}

/* The dots/bullets/indicators */
.dotted {
  cursor: pointer;
  height: 4px;
  width: 4px;
  margin: 0 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dotted:hover {
  background-color: white;
  height: 6px;
  width: 6px;
  align-self: center;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */

@media only screen and (max-width: 768px) {
  .slideshow-container {
    background: #3799f4;
    padding-bottom: 40px;
    z-index: 0;
    .mySildes-Images {
      margin-top: -30px;
    }
  }

  .text {
    display: none;
  }

  .numbertext {
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: bold;
    font-family: $defultTextFont;
    margin-top: 30px;
    text-align: center;
  }
}

@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}
